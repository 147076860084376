@font-face {
  font-family: cs;
  src: url('../resources/fonts/rocky.otf');
}

.sedan-sc-regular {
  font-family: "cs", serif;
  font-weight: 400;
  font-style: normal;
}

html {
  --scrollbarBG: white;
  --thumbBG: #818589;
  font-family: "cs", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.medium-width {
  width: 90%;
  margin: 20px 0px;
}

.full-width {
  width: 90%;
  margin: 30px 0px;
}

.width-40 {
  width: 40%;
}

.flex-container-small {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

body::-webkit-scrollbar {
  width: 11px;
}

.Small-img {
  margin-top: 20px;
  margin-bottom: 20px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.flex-ctr-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 32px;
  padding: 20px 0px;
}

.medium-text {
  font-size: 18px;
}

.margin-top {
  margin-top: 10px;
}

.flex-ctr {
  display: flex;
}

.width-100 {
  width: 100%;
}

.brown-bg {
  background-color: #5a3817;
}

body {
  font-family: "cs", serif;
  font-optical-sizing: auto;
  background-color: #E18933;
  color: white;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

h1 {
  font-family: "Sedan SC", serif;
  font-size: 48px;
  color: #FFC300;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.5px;
}

/* Create reusable classes later using the below */
small-text {
  font-size: 12px;
}

small-img {
  height: 300px;
}

.flex-align-container {
  display: flex;
  width: 100%;
}

.pd-btm {
  padding-bottom: 30px;
}

.small-img {
  height: 300px;
}

.medium image h2 {
  font-size: 32px;
}

.big-text {
  font-size: 32px;
}

/* video background */
#background-video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

/* page content */
h1, h2 {
  font-weight: bold;
  text-align: center;
}

h1 {
  font-size: 6rem;
  margin-top: 30vh; 
}

h2 { font-size: 3rem; }

@media (max-width: 750px) {
    #background-video { display: none; }
    body {
      background: url("https://assets.codepen.io/6093409/river.jpg") no-repeat;
      background-size: cover;
    }
}



p {
  font-size: 24px;
  margin: 0px;
}

@media (min-width: 1024px) {
  h1 {
    font-size: 58px;
  }

  h2 {
    font-size: 42px;
  }

  p {
    font-size: 24px;
  }
}
