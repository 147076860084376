/* Top Footer styles */
.Navbar-main-logo {
  height: 30px;
}

.Navbar {
  display: flex;
  position: sticky;
  justify-content: flex-end;
  z-index: 20;
  padding: 10px 30px;
  background-color: #cecbcb38;
  top: 0;
  border-bottom: solid 2px #323232;
}

.Navbar-social-logo {
  height: 50px;
  margin: 0px 5px;
  vertical-align: middle;
}

/* Bottom Footer styles */
.Navbar-bottom {
  display: flex;
  position: sticky;
  justify-content: space-between;
  z-index: 20;
  padding: 10px 30px;
  background-color: #f3f3f4;
  opacity: 0.9;
  top: 0;
  border-top: solid 2px #323232;
}